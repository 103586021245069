import React from "react";
import "../../common/componentsStyles/tooltip.styl";
import { ProjectSwitcher } from "../../projectSwitcher";
import TagManager from "react-gtm-module";
import { createRoot } from "react-dom/client";

if (!window?.google_tag_manager) {
  const tagManagerArgs = {
    gtmId: "GTM-N2GH86",
    dayaLayer: window.dataLayer || [],
  };

  TagManager.initialize(tagManagerArgs);
}
if (document.getElementById("root")) {
  const render = () => {
    const rootElement = document.getElementById("root");
    const root = createRoot(rootElement);
    root.render(<ProjectSwitcher defaultProject={"copacabana"} />);
  };

  if (
    "fetch" in window
  ) {
    render();
  } else {
    import("./polyfills").then(render);
  }
}
